import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgentRedirectService {
  get isMobile() {
    return /Mobi|iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  redirectToMobileIfNeeded() {
    if (this.isMobile) {
      const hostname = window.location.hostname;
      const protocol = window.location.protocol;
      window.location.href = `${protocol}//mobile.${hostname}`;
    }
  }
}
